<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">
                <div class="card-title align-items-start flex-column">
                  <h3 class="card-label font-weight-bolder text-dark">
                    Create Observation
                  </h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1"
                    >Log a new observation</span
                  >
                </div>
              </slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link to="/observations" v-slot="{ href, navigate }">
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="View All Observations"
                  @click="navigate"
                >
                  <i class="fas fa-list pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <div class="row">
              <form
                @submit.prevent="submit"
                class="form col-md-10 mx-auto mt-5"
                novalidate="novalidate"
                autocomplete="false"
                id="observation_form"
              >
                <div class="pb-5">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Observation Title</label>
                        <input
                          class="form-control form-control-solid form-control-lg"
                          type="text"
                          placeholder="Observation Title"
                          autocomplete="off"
                          :class="{ 'is-invalid': $v.observation_title.$error }"
                          v-model.trim="$v.observation_title.$model"
                        />
                        <template v-if="$v.observation_title.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.observation_title.required"
                          >
                            Observation title is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please enter your observation title.</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Observation Location</label>
                        <multiselect
                          v-model="location"
                          :options="locations"
                          track-by="name"
                          label="name"
                          placeholder="Select A Location"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                        </multiselect>
                        <template v-if="$v.location.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.location.required"
                          >
                            Observation location is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please select observation's location.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Description"
                      value=""
                      rows="5"
                      :class="{ 'is-invalid': $v.description.$error }"
                      v-model.trim="$v.description.$model"
                    />
                    <template v-if="$v.description.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.description.required"
                      >
                        Observation description is required
                      </span>
                    </template>
                    <span class="form-text text-muted"
                      >Please describe the observation.</span
                    >
                  </div>
                  <div class="form-group">
                    <label>Action Taken</label>
                    <textarea
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Action Taken"
                      rows="5"
                      :class="{ 'is-invalid': $v.action.$error }"
                      v-model.trim="$v.action.$model"
                    />
                    <template v-if="$v.action.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.action.required"
                      >
                        Action taken is required
                      </span>
                    </template>
                  </div>
                  <div class="form-group">
                    <label>Recommendation</label>
                    <textarea
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Recommendation"
                      rows="5"
                      :class="{ 'is-invalid': $v.recommendation.$error }"
                      v-model.trim="$v.recommendation.$model"
                    />
                    <template v-if="$v.recommendation.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.recommendation.required"
                        >Recommendation is required
                      </span>
                    </template>
                    <span class="form-text text-muted"
                      >What are your recommendations on this observation.</span
                    >
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Observation Date</label>
                        <b-form-datepicker
                          :max="max"
                          v-model="$v.timestamp.$model"
                          class="form-control form-control-solid form-control-lg"
                          :class="{ 'is-invalid': $v.timestamp.$error }"
                        ></b-form-datepicker>
                        <template v-if="$v.timestamp.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.timestamp.required"
                            >Observation date is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please pick your observation date.</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Observation Status</label>
                        <multiselect
                          v-model="$v.status.$model"
                          :options="statuses"
                          track-by="name"
                          label="name"
                          placeholder="Select A Status"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                        </multiselect>
                        <template v-if="$v.status.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.status.required"
                            >Observation status is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please select observation status.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Observation Category</label>
                        <multiselect
                          v-model="$v.category.$model"
                          :options="categories"
                          track-by="name"
                          label="name"
                          placeholder="Select A Category"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                        </multiselect>
                        <template v-if="$v.category.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.category.required"
                            >Observation category is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please select an observation category.</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Observation Type</label>
                        <multiselect
                          v-model="$v.type.$model"
                          :options="types"
                          track-by="name"
                          label="name"
                          name="type"
                          ref="type"
                          placeholder="Select A Type"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                        </multiselect>
                        <template v-if="$v.type.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.type.required"
                            >Observation type is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please select an observation type.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Action Party</label>
                        <multiselect
                          v-if="currentUser.company.action_party_type == 'user'"
                          v-model="$v.party.$model"
                          :options="parties"
                          track-by="firstname"
                          :custom-label="fullname"
                          placeholder="Select Action Party"
                        >
                          <template slot="singleLabel" slot-scope="{ option }"
                            >{{ option.firstname }}
                            {{ option.lastname }}</template
                          >
                        </multiselect>
                        <multiselect
                          v-if="
                            currentUser.company.action_party_type == 'group'
                          "
                          v-model="$v.party.$model"
                          :options="parties"
                          track-by="name"
                          label="name"
                          placeholder="Select Action Party"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                        </multiselect>
                        <template v-if="$v.party.$error">
                          <span
                            class="form-text text-danger"
                            v-if="!$v.party.required"
                            >Observation action party is required
                          </span>
                        </template>
                        <span class="form-text text-muted"
                          >Please select action party.</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Observation Evidence</label>
                        <b-form-file
                          class="form-control form-control-solid form-control-lg"
                          accept="image/jpeg, image/png, image/gif"
                          name="evidences"
                          ref="evidences"
                          id="evidences"
                          multiple
                          v-on:input="handleFileUpload()"
                        ></b-form-file>
                        <span class="form-text text-muted"
                          >Please upload an image of the observation.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end border-top pt-10 pb-10">
                  <div>
                    <router-link to="/observations" v-slot="{ href, navigate }">
                      <a
                        :href="href"
                        class="btn btn-light-danger font-weight-bold px-9 py-4 mr-2"
                        @click="navigate"
                      >
                        Cancel
                      </a>
                    </router-link>
                    <button
                      :disabled="loading"
                      type="submit"
                      class="btn btn-primary font-weight-bold px-9 py-4"
                    >
                      <b-spinner
                        v-if="loading == true"
                        variant="light"
                        label="Spinning"
                      ></b-spinner>
                      <span v-if="loading == false">Submit Observation</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.b-form-btn-label-control.form-control {
  height: 45px;
}
.form-group.hasError label {
  color: red;
}
.b-form-btn-label-control.form-control > .form-control {
  padding-top: 11px;
  font-size: 13px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import GeneralService from "@/core/services/general.service.js";
import ActionPartyService from "@/core/services/actionparty.service.js";
import UserService from "@/core/services/user.service.js";
import LocationService from "@/core/services/location.service.js";
import ObservationClassificationService from "@/core/services/observation-classification.service.js";
import ObservationTypesService from "@/core/services/observation-types.service.js";
import ObservationService from "@/core/services/observation.service.js";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    return {
      observation_title: "",
      description: "",
      action: "",
      recommendation: "",
      location: null,
      status: null,
      timestamp: "",
      category: null,
      type: null,
      party: null,
      max: maxDate,
      locations: [],
      statuses: [],
      categories: [],
      types: [],
      parties: [],
      evidences: [],
      loading: false
    };
  },
  validations: {
    observation_title: { required },
    location: { required },
    description: { required },
    action: { required },
    recommendation: { required },
    status: { required },
    timestamp: { required },
    category: { required },
    type: { required },
    party: { required }
  },
  components: {
    PilotCard,
    Multiselect
  },
  methods: {
    fullname({ firstname, lastname }) {
      return `${firstname} ${lastname}`;
    },
    getActionParties() {
      if (this.currentUser.company.action_party_type === "group") {
        ActionPartyService.all()
          .then(response => {
            this.parties = response.data;
          })
          .catch(error => {
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Could not populate observation types at the moment, please give it a minute and refresh page"
            );
            console.log(error);
          });
      } else {
        UserService.all()
          .then(response => {
            this.parties = response.data;
          })
          .catch(error => {
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Could not populate observation types at the moment, please give it a minute and refresh page"
            );
            console.log(error);
          });
      }
    },
    getLocations() {
      LocationService.all()
        .then(response => {
          this.locations = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate company sectors at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getCategories() {
      ObservationClassificationService.all()
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate classifications at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getTypes() {
      ObservationTypesService.all()
        .then(response => {
          this.types = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate observation types at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getStatuses() {
      GeneralService.statuses()
        .then(response => {
          this.statuses = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate observation types at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;

        var data = {
          location_id: this.location.id,
          classification_id: this.category.id,
          observation_type_id: this.type.id,
          title: this.observation_title,
          status_id: this.status.id,
          timestamp: this.timestamp,
          description: this.description,
          action_taken: this.action,
          recommendation: this.recommendation,
          evidences: this.evidences
        };

        if (this.currentUser.company.action_party_type === "group") {
          data.action_party_id = this.party.id;
        } else {
          data.action_party_user_id = this.party.id;
        }

        ObservationService.create(data)
          .then(() => {
            this.location = null;
            this.category = null;
            this.type = null;
            this.party = null;
            this.observation_title = "";
            this.status = null;
            this.timestamp = null;
            this.observation_title = "";
            this.action = "";
            this.recommendation = "";
            this.description = "";
            this.evidences = [];

            this.$nextTick(() => {
              this.$v.$reset();
            });

            Swal.fire({
              title: "Observation Submitted",
              text: "Observation has been successfully submitted",
              icon: "success",
              heightAuto: false
            });
            this.loading = false;
          })
          .catch(error => {
            if (error.data) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. please give it a minute and refresh page"
              );
            }
            this.loading = false;
          });
      }
    },
    handleFileUpload() {
      const files = this.$refs.evidences.files;
      files.forEach(file => {
        this.getBase64(file).then(data => {
          this.evidences.push(data);
          console.log(JSON.stringify(this.evidences));
        });
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"]),

    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  },
  mounted() {
    this.getActionParties();
    this.getLocations();
    this.getCategories();
    this.getTypes();
    this.getStatuses();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Observations", route: "observations" },
      { title: "Log Observation" }
    ]);
  }
};
</script>
